<template>
    <div class="account-orders container py-5">
        <account-order-card v-for="order in orders"
                            v-bind:key="order._id"
                            v-bind:order="order">
        </account-order-card>
    </div>
</template>

<script>
import AccountOrderCard from "./AccountOrders/OrderCard";
export default {
    name: "AccountOrders",
    components: {AccountOrderCard},
    metaInfo () {
        return {
            title: this.$t("message.account.orders"),
            meta: [{
                "name": "description",
                "content": this.$t("message.account.orders"),
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": this.$t("message.account.orders"),
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.$t("message.account.orders"),
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "website",
                "vmid": "og:type"
            }]
        }
    },
    data () {
        return {

        }
    },
    computed: {
        orders () {
            return this.$store.getters["mall/shopOrders"]
                .slice(0)
                .reverse();
        },
    }
}
</script>

<style lang="scss" scoped>
.account-orders {
    color: rgba(113, 113, 113, 1);
}
</style>
