<template>
    <div class="card mb-4">
        <div class="card-header">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 d-flex">
                        <div class="col-md-4">
                            <p class="mb-0">
                                <strong>ORDER PLACED</strong>
                            </p>
                            <p class="text-nowrap mb-1">
                                {{ order.createdAt | dateFnsFormatter("yyyy/M/d") }}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <p class="mb-0">
                                <strong>DISCOUNT</strong>
                            </p>
                            <p class="text-nowrap mb-1">
                                <span v-if="parseInt(discountTotal)">-</span>HK${{ discountTotal | currency("", 2) }}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <p class="mb-0">
                                <strong>TOTAL</strong>
                            </p>
                            <p class="text-nowrap mb-1">
                                HK${{ orderTotal | currency("", 2)}}
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 text-md-right">
                        <p class="text-nowrap mb-0">
                            <strong>ORDER # {{ order._id }}</strong>
                        </p>
                        <!-- <p class="mb-1">Details | Invoice</p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col-md-9">
                        <ul class="list-unstyled">
                            <li class="media py-3 align-items-stretch"
                                v-for="item in order.items"
                                v-bind:key="item._id">
                                <div class="item-image-wrapper mr-2"
                                     v-if="item.images.length > 0">
                                    <img class="d-block mx-auto item-image"
                                         v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ item.images[0] }`"
                                         v-bind:alt="`${ item.title[$i18n.locale] } | ${ item.subtitle[$i18n.locale] }`">
                                </div>
                                <div class="media-body">
                                    <h5 class="mt-0 mb-1"
                                        v-html="item.title[$i18n.locale]">
                                    </h5>
                                    <p class="mb-0" v-html="item.subtitle[$i18n.locale]"></p>
                                    <div>
                                        <p class="d-inline-block">
                                            {{ $t("message.basket.unit-price") }}
                                        </p>
                                        <h5 class="d-inline-block">
                                            <small>HK$</small>{{ item.prices[0].hkd | currency("", 0) }}
                                        </h5>
                                    </div>
                                    <div>
                                        <p class="d-inline-block">
                                            {{ $t("message.basket.quantity") }}:
                                        </p>
                                        <h5 class="d-inline-block">
                                            {{ item.quantity }}
                                        </h5>
                                    </div>
                                    <div>
                                        <p class="d-inline-block">
                                            {{ $t("message.basket.sub-total") }}:
                                        </p>
                                        <h5 class="d-inline-block">
                                            <small>HK$</small>{{ item.quantity * item.prices[0].hkd | currency("", 0) }}
                                        </h5>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <router-link class="btn btn-block btn-dark"
                                     v-if="order.status === 'pending' || !order.payment"
                                     v-bind:to="{ name: 'checkout-payment', params: { type: 'products', orderId: order._id } }">
                            {{ $t("message.account.pay-order") }}
                        </router-link>
                        <!-- <button class="btn btn-block btn-dark"
                                v-if="['pending', 'accepted'].includes(order.status)">
                            {{ $t("message.account.update-order") }}
                        </button> -->
                        <button class="btn btn-block btn-danger"
                                v-if="['pending', 'accepted'].includes(order.status)">
                            {{ $t("message.account.cancel-order") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountOrderCard",
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {

        };
    },
    computed: {
        deliveryTotal () {
            return this.order?.deliveryTotal ?? 0;
        },
        discountTotal () {
            return this.order?.discountTotal ?? 0;
        },
        orderTotal () {
            return this.order?.orderTotal ?
                this.order?.orderTotal :
                this.order.items.reduce(
                    (acc, item) => acc += item.quantity * item.prices[0].hkd,
                    0
                ) + parseFloat(this.deliveryTotal);
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.item-image-wrapper {
    min-width: 120px;

    & > .item-image {
        height: 150px;
        max-width: 100%;
    }
}
</style>
